body {
    font-family: "SegoeUI-Semibold", "Segoe UI Regular", SegoeUI,"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    color: #666
}

.wcu_circularprogress {
    display: flex;
    margin-top: 15%;
    justify-content: center;
}

.wcu_iconButton {
    margin-right: 5px;
    width: auto
}

.wcu_full_width {
    width: 100%;
}

.wcu_box_padding {
    padding: 0 1px 0 2px;
}
