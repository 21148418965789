.userInput {
    padding-bottom: 5px;
    border: none;
    outline: none;
    border-bottom: 1px solid #555;
    margin-bottom: 25px;
    width: 300px;
    font-size: 1em;
    text-align: center;
    border-radius: 0;
}

.signButton {
    background-color: #0265a8;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 5px;
    width: 200px;
    cursor: pointer;
    font-size: 1em;
}

.appDocuSign {
    padding: .75vw;
    width: 100%;
    box-sizing: border-box;
}

.lobby {
  margin-top: 100px;
  text-align: center;
  font-size: 1.25em;
}

.chatHeader {
    width: 100%;
    background-color: #001433;
    text-align: center;
    font-size: initial;
    display : flex;
    padding:13px
}
.inputForm{
    margin-left : 12%;
    margin-top : 40%;
    padding : 3px;
}
.formElement{
    margin-top : 10px;
}

