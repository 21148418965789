
.signInButton {
    width: 80px;
    height: 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: teal;
    color: white;
}
