
.userInput {
    padding-bottom: 5px;
    border: none;
    outline: none;
    border-bottom: 1px solid #555;
    margin-bottom: 25px;
    width: 300px;
    font-size: 0em;
    text-align: center;
    border-radius: 0;
}

.signButton {
    background-color: #0265a8;
    font-weight: bold;
    display: block;
    margin-top:5px  ;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 150px;
    cursor: pointer;
    font-size: 1em;
}
.signButton:disabled {
    background-color: gray;
}

.appUploadDocument {
    padding: .75vw;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 45px;
}

.lobby {
  margin-top: 10px;
  text-align: center;
  font-size: 1.25em;
}
//Wescom
// //header css
#main-header {
    background-color: #034F84;
    //color: white;
    width: 100%;
    max-width: 100vw;
    justify-content: center;
    flex-direction: column;
    z-index: 6;
    display: flex;
    top: 0;
    // position: fixed;
}

.header-wrapper {
    width: 100%;
    display: flex;
    max-width: 90rem;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
    transition: min-height .5s;
    z-index: 2;
}
.header-wrapper div#logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    max-width: 100vw;
    padding: 0 1.25rem;
    width: 100%;
    flex-wrap: wrap;
}

#logo {
    // display: flex;
    padding: 0 0.9375rem;
    flex-direction: column;
    flex: 1;
    align-items: center;
    width: calc(100% - 10rem);
    // position: absolute;
    margin: 0 auto;
    right: 5rem;
    transition: opacity 1s, all 0.5s;
    overflow: hidden;
}

#logo a {
    display: flex;
    max-height: 2.5rem;
    height: 100%;
    width: auto;
}

#logo img {
    transition: opacity 0.5s, all 0.5s;
    max-height: 2.5rem;
    width: auto;
    max-width: unset;
    height: 100%;
}


//footer css
#footer .footer-wrapper {
    width: 100%;
    background: #034F84;
    color: white;
    font-family: "Work Sans", sans-serif;
    display: flex;
    flex-direction: row;
    bottom: 0;
    position: fixed;
}
#footer .footer-wrapper .footer-content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 1.875rem;
}
#footer .footer-wrapper .footer-content .footer-topbar {
    /* margin-top: 1.25rem; */
    padding: .625rem 0;
    justify-content: center;
    display: flex;
}
#footer .footer-wrapper .footer-content .footer-topbar img {
    width: 175px;
    height: auto;
}