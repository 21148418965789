/* 11-21-2022 => ONKAR => Texting-843: DEC Release - Vertical Scroll Bar styling  
*10-17-2023 => SUDAM B CHAVAN => TEXTING-1646 Reporting - Realtime Reports Design
*/

.MuiDataGrid-columnHeaderTitle {
  color: white !important;
  ;
  font-weight: bold !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon.css-1sj8v5k-MuiSvgIcon-root {
  color: white !important;
  ;
}

/* .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-1sj8v5k-MuiSvgIcon-root {
  color: white;
} */
/* .MuiDataGrid-columnHeaders.css-okt5j6-MuiDataGrid-columnHeaders {
  background-color: #505f79 !important;;
} */
.super-app-theme--even {
  background-color: #eeeeee !important;
  ;
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px
}

::-webkit-scrollbar-thumb {
  background: #034f84;
  border-radius: 10px;
  border: 1px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  border: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

button:focus {
  outline: none !important;
}

.userName {
  font-size: 14px;
  font-weight: bold;
}

.phoneNumber {
  font-size: 12px;
}
.fs-12{
  font-size: 12px;
} 

.menuitems {
  display: flex !important;
  flex-direction: "column" !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-top: unset !important;
  margin-bottom: unset !important;
}
 .dashboardContainer{
     height: 90vh;
  }